import { Controller } from '@hotwired/stimulus';

const acceptedTypes = ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'];

export default class extends Controller {
  static targets = ['errorDisplay', 'localeOverlay'];

  static values = {
    fileTypeError: String,
    imageSizeError: String,
    pdfSizeError: String,
    disabled: Boolean,
  };

  connect() {
    this.element.addEventListener('trix-file-accept', this.handleFileUpload.bind(this));

    this.element.addEventListener('trix-initialize', () => {
      if (this.disabledValue === true) {
        this.element.querySelectorAll('trix-editor').forEach((trixEditor) => {
          trixEditor.editor.element.setAttribute('contentEditable', false);

          const toolbarButtons = trixEditor.toolbarElement.querySelectorAll('button');
          toolbarButtons.forEach((button) => {
            if (button.dataset.trix) {
              // eslint-disable-next-line no-param-reassign
              button.disabled = true;
              button.removeAttribute('data-trix-attribute');
              button.removeAttribute('data-trix-key');
              button.removeAttribute('data-trix-action');
            }
          });
        });
      }
    });
  }

  displayErrorMessage(message) {
    this.errorDisplayTarget.innerText = message;
    this.errorDisplayTarget.dataset.visible = true;
  }

  hideErrorMessage() {
    this.errorDisplayTarget.dataset.visible = false;
  }

  checkFileAcceptance(event) {
    if (!acceptedTypes.includes(event.file.type)) {
      event.preventDefault();
      this.displayErrorMessage(this.fileTypeErrorValue);
      return;
    }

    switch (event.file.type) {
      case 'application/pdf':
        if (event.file.size > 10485760) {
          event.preventDefault();
          this.displayErrorMessage(this.pdfSizeErrorValue);
        }
        break;
      default:
        if (event.file.size > 2097152) {
          event.preventDefault();
          this.displayErrorMessage(this.imageSizeErrorValue);
        }
    }
  }

  handleFileUpload(event) {
    this.hideErrorMessage();
    this.checkFileAcceptance(event);
  }

  updateOverlay(event) {
    this.localeOverlayTarget.textContent = event.params.locale;
  }

  switchLocaleInput(event) {
    this.element.querySelectorAll('[data-locale-input]')
      .forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.hidden = element.getAttribute('data-locale-input') !== event.params.locale;
      });
  }
}
