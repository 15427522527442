import { Controller } from '@hotwired/stimulus';
import {
  BarController,
  BarElement,
  Chart,
  LinearScale,
  TimeScale,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-adapter-luxon';

export default class extends Controller {
  static targets = ['chart', 'select', 'title', 'exportLink'];

  chart;

  data;

  titles;

  connect() {
    const locale = document.querySelector('html').getAttribute('lang');
    const {
      defaultTimeBucket,
      calendarWeekAbbr,
    } = this.chartTarget.dataset;

    this.updateExportLinkTimeBucket(defaultTimeBucket);

    Chart.register(
      BarController,
      BarElement,
      TimeScale,
      LinearScale,
      ChartDataLabels,
    );

    this.titles = JSON.parse(this.chartTarget.dataset.titles);

    this.data = JSON.parse(this.chartTarget.dataset.dataPerTimeBucket);
    Object.values(this.data).forEach((values) => {
      values.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.x = new Date(element.x);
      });
    });

    const chartData = {
      datasets: [{
        data: this.data[defaultTimeBucket],
        backgroundColor: '#0066CC',
        borderWidth: 0,
        categoryPercentage: 1.0,
        barPercentage: 0.85,
      }],
    };

    const config = {
      type: 'bar',
      data: chartData,
      options: {
        maintainAspectRatio: false,
        animation: { duration: 0 },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: defaultTimeBucket,
              displayFormats: {
                week: `'${calendarWeekAbbr}'W`,
                month: 'LLL',
                quarter: "'Q'q",
                year: 'y',
              },
              isoWeekday: true,
            },
            adapters: { date: { locale, zone: 'UTC' } },
            border: { display: false },
            grid: { display: false },
            ticks: {
              color: '#52525B',
              font: { size: 12, weight: 350 },
              padding: 0,
            },
          },
          y: {
            beginAtZero: true,
            border: { display: false },
            grid: {
              display: true,
              color: '#E4E4E7',
              drawTicks: true,
            },
            ticks: {
              color: '#52525B',
              font: { size: 12, weight: 350 },
              padding: 6,
              maxTicksLimit: 4,
              precision: 0,
            },
            position: 'right',
          },
        },
        events: null,
        layout: {
          padding: {
            top: 25,
          },
        },
        plugins: {
          tooltip: { enabled: false },
          datalabels: {
            color: '#18181B',
            font: { size: 14, weight: 400 },
            anchor: 'end',
            align: 'top',
            offset: 0,
            formatter: (value) => value.y_label || value.y,
          },
        },
      },
    };

    this.chart = new Chart(this.chartTarget, config);
  }

  select(event) {
    this.chart.config.options.scales.x.time.unit = event.target.value;
    this.chart.config.data.datasets[0].data = this.data[event.target.value];
    this.chart.update();

    this.titleTarget.innerHTML = this.titles[event.target.value];

    this.updateExportLinkTimeBucket(event.target.value);
  }

  updateExportLinkTimeBucket(timeBucket) {
    if (this.hasExportLinkTarget) {
      const url = new URL(this.exportLinkTarget);
      url.searchParams.set('time_bucket', timeBucket);
      this.exportLinkTarget.href = url.href;
    }
  }
}
