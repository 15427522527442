import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['recommendationCheckBox', 'endPageHeadline', 'endPageDescription'];

  connect() {
    this.toggleFields();
  }

  toggleFields() {
    const isChecked = this.recommendationCheckBoxTarget.querySelector('input').value === 'true';

    this.toggleEndPageHeadlineInput(this.endPageHeadlineTarget, isChecked);
    this.toggleEndPageDescriptionInput(this.endPageDescriptionTarget, isChecked);
  }

  toggleEndPageHeadlineInput(containerTarget, isChecked) {
    const inputs = containerTarget.querySelectorAll('input');
    inputs.forEach((input) => {
      input.disabled = isChecked;
    });
  }

  toggleEndPageDescriptionInput(containerTarget, isChecked) {
    const editors = containerTarget.querySelectorAll('trix-editor');
    const toolbar = containerTarget.querySelector('trix-toolbar');
    editors.forEach((editor) => {
      editor.setAttribute('contenteditable', !isChecked);
    });

    toolbar.querySelectorAll('button').forEach((input) => {
      input.disabled = isChecked;
    });
  }
}
