import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['uploadNotice', 'deleteNotice', 'image', 'fileField', 'deleteCheckBox'];

  onImageClick() {
    this.fileFieldTarget.click();
  }

  onImageChosen(event) {
    const url = URL.createObjectURL(event.currentTarget.files[0]);
    this.imageTarget.setAttribute('src', url);
    this.imageTarget.classList.remove('hidden');
    this.uploadNoticeTarget.classList.remove('hidden');

    this.deleteCheckBoxTarget.checked = false;
    this.deleteNoticeTarget.classList.add('hidden');
  }

  onImageDelete(event) {
    if (event.target.checked) {
      this.deleteNoticeTarget.classList.remove('hidden');

      this.imageTarget.classList.add('hidden');
      this.uploadNoticeTarget.classList.add('hidden');
    } else {
      this.deleteNoticeTarget.classList.add('hidden');

      this.imageTarget.classList.remove('hidden');
    }
  }
}
