import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['submitSearchForm'];

  searchInput = (element) => {
    const searchTerm = element.target.value;
    if (searchTerm === '') {
      this.submitSearchFormTarget.click();
    }
  };
}
