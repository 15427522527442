import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['submitButton'];

  static values = { requiredQuestion: Boolean };

  connect() {
    if (this.requiredQuestionValue !== true) {
      return;
    }

    if (this.answerIsEmpty() || this.checkboxesNotChecked() || this.radioButtonNotChecked()) {
      this.setButtonAttributes(true);
    }

    if (this.textInput()) {
      this.textInput().addEventListener('input', this.handleAnswerEvent.bind(this));
    } else if (this.checkBoxInputPresent()) {
      this.checkBoxInput().forEach((checkbox) => {
        checkbox.addEventListener('change', this.handleAnswerEvent.bind(this));
      });
    } else if (this.radioInputPresent()) {
      this.radioInput().forEach((radioButton) => {
        radioButton.addEventListener('radioClick', this.handleAnswerEvent.bind(this));
      });
    }
  }

  disconnect() {
    if (this.requiredQuestionValue !== true) {
      return;
    }

    if (this.textInput()) {
      window.removeEventListener('input', this.handleAnswerEvent.bind(this));
    } else if (this.checkBoxInputPresent()) {
      this.checkBoxInput().forEach((checkbox) => {
        checkbox.removeEventListener('change', this.handleAnswerEvent.bind(this));
      });
    } else if (this.radioInputPresent()) {
      this.radioInput().forEach((radioButton) => {
        radioButton.removeEventListener('radioClick', this.handleAnswerEvent.bind(this));
      });
    }
  }

  handleAnswerEvent(event) {
    if (this.requiredQuestionValue !== true) {
      return;
    }

    if (this.textInput() && event.target === this.textInput()) {
      this.setButtonAttributes(this.answerIsEmpty());
    } else if (this.checkBoxInputPresent()) {
      this.setButtonAttributes(this.checkboxesNotChecked());
    } else if (this.radioInputPresent()) {
      this.setButtonAttributes(this.radioButtonNotChecked());
    }
  }

  checkboxesNotChecked() {
    return this.checkBoxInputPresent()
      && [...this.checkBoxInput()].every((checkbox) => !checkbox.checked);
  }

  answerIsEmpty() {
    return this.textInput()?.value?.trim() === '';
  }

  radioButtonNotChecked() {
    return this.radioInputPresent()
      && [...this.radioInput()].every((radioButton) => !radioButton.checked);
  }

  textInput() {
    return document.querySelector('[data-question-type="text"]');
  }

  radioInput() {
    return document.querySelectorAll('[data-question-type="radio"]');
  }

  checkBoxInput() {
    return document.querySelectorAll('[data-question-type="checkbox"]');
  }

  radioInputPresent() {
    return this.radioInput().length > 0;
  }

  checkBoxInputPresent() {
    return this.checkBoxInput().length > 0;
  }

  setButtonAttributes(value) {
    this.submitButtonTarget.disabled = value;
    this.submitButtonTarget.setAttribute('aria-disabled', value);
    this.submitButtonTarget.setAttribute('tabindex', value ? -1 : 0);
  }
}
