const informIframeParent = () => {
  const element = document.getElementById('main_container');
  if (!element) return;
  let height;

  if (height !== element.offsetHeight) {
    height = element.offsetHeight;
    window.parent.postMessage(
      {
        frameHeight: height,
      },
      '*',
    );
  }
};

document.addEventListener('turbo:load', informIframeParent);

window.onload = informIframeParent;
window.onresize = informIframeParent;
