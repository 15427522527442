import { Controller } from '@hotwired/stimulus';
import {
  Chart,
  Filler,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  TimeScale,
  TimeSeriesScale,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-luxon';

export default class extends Controller {
  static targets = ['chart'];

  static values = {
    submissions: Object,
    yMax: Number,
    xMin: String,
    xMax: String,
    locale: String,
  };

  connect() {
    const dates = Object.keys(this.submissionsValue);
    const cumulativeCounts = Object.values(this.submissionsValue);

    // Register necessary components with Chart.js
    Chart.register(
      Filler,
      LineController,
      LineElement,
      PointElement,
      LinearScale,
      Title,
      CategoryScale,
      TimeScale,
      TimeSeriesScale,
      Tooltip,
    );

    const locale = this.localeValue;

    const ctx = this.chartTarget.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 100);
    gradient.addColorStop(0, '#C2DAF3');
    gradient.addColorStop(1, '#FFF');

    // Add transparency to the end of the gradient to not draw on top of grid border
    gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');

    this.chart = new Chart(this.chartTarget, {
      type: 'line',
      data: {
        labels: dates,
        datasets: [
          { // The submission count line.
            data: cumulativeCounts,
            borderColor: '#0066CC',
            borderWidth: 1.5,
            tension: 0,
            pointBackgroundColor: '#0066CC',
            pointHoverRadius: 2,
            pointRadius: (context) => {
              if (context.dataIndex === context.dataset.data.length - 1) {
                return 2;
              }
              return 0;
            },
          },
          { // The target line.
            data: [
              { x: this.xMinValue, y: 0 },
              { x: this.xMaxValue, y: this.yMaxValue },
            ],
            borderColor: '#61A0DF',
            borderDash: [4, 4],
            borderWidth: 1.5,
            pointHoverRadius: 0,
            pointRadius: 0,
            events: [],
          },
          { // The fill under the submission count line. Rendered last to be below the target line.
            data: cumulativeCounts,
            fill: 'start',
            backgroundColor: gradient,
            borderWidth: 0,
            tension: 0,
            pointHoverRadius: 0,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        layout: {
          padding: {
            left: -8,
          },
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
            },
            min: this.xMinValue,
            max: this.xMaxValue,
            offset: false,
            border: {
              color: '#D6D3D1',
              width: 1,
            },
            grid: {
              drawOnChartArea: false,
              color: '#D6D3D1',
              tickWidth: 1,
            },
            ticks: {
              align: 'inner',
              maxRotation: 0,
              minRotation: 0,
              callback: (value, index, values) => {
                const date = new Date(value);

                if (index === 0 || index === values.length - 1) { // First and last date
                  return date.toLocaleDateString(locale);
                }
                return null;
              },
            },
          },
          y: {
            beginAtZero: true,
            min: 0,
            max: this.yMaxValue,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
            border: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            boxWidth: 8,
            boxHeight: 8,
            mode: 'nearest',
            intersect: false,
            backgroundColor: '#FFF',
            borderColor: '#D6D3D1',
            borderWidth: 1,
            titleColor: '#78716C',
            titleFont: { weight: 'normal' },
            bodyColor: '#78716C',
            filter(tooltipItem) {
              return tooltipItem.datasetIndex === 0;
            },
            callbacks: {
              title(tooltipItems) {
                if (tooltipItems[0] === undefined) {
                  return '';
                }
                const date = new Date(tooltipItems[0].parsed.x);
                return date.toLocaleDateString(locale);
              },
              labelColor() {
                return {
                  borderColor: 'rgba(0, 0, 0, 0)',
                  backgroundColor: '#0066CC',
                  borderWidth: 0,
                };
              },
            },
          },
        },
      },
    });
  }
}
