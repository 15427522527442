import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['deleteButton'];

  static values = {
    checkedCount: Number,
  };

  initialize() {
    this.updateButtonDisabledState();
  }

  connect() {
    super.connect();
    this.checkboxes().forEach((checkbox) => {
      checkbox.addEventListener('change', this.anyCheckboxIsChecked);
    });
  }

  disconnect() {
    super.disconnect();
    this.checkboxes().forEach((checkbox) => {
      checkbox.removeEventListener('change', this.anyCheckboxIsChecked);
    });
  }

  anyCheckboxIsChecked = () => {
    this.updateButtonDisabledState();
  };

  updateButtonDisabledState() {
    const checkedBoxes = this.checkboxes().filter((checkbox) => checkbox.checked);
    this.checkedCountValue = checkedBoxes.length;

    if (this.checkedCountValue > 0 && this.deleteButtonTarget.disabled) {
      this.deleteButtonTarget.disabled = false;
      this.deleteButtonTarget.setAttribute('aria-disabled', false);
      this.deleteButtonTarget.removeAttribute('tabindex');
    } else if (this.checkedCountValue === 0 && !this.deleteButtonTarget.disabled) {
      this.deleteButtonTarget.disabled = true;
      this.deleteButtonTarget.setAttribute('aria-disabled', true);
      this.deleteButtonTarget.setAttribute('tabindex', -1);
    }
  }

  checkboxes() {
    return Array.from(this.element.querySelectorAll('input[type="checkbox"]'));
  }
}
