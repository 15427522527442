import { Controller } from '@hotwired/stimulus';

const DISPLAY_NOTIFICATION_DURATION_MS = 4000;

export default class extends Controller {
  connect() {
    setTimeout(this.hide.bind(this), DISPLAY_NOTIFICATION_DURATION_MS);
  }

  hide() {
    this.element.classList.add('hidden');
  }
}
