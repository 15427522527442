import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';
import { patch } from '@rails/request.js';

export default class extends Controller {
  static targets = ['sortableItem', 'sortableList', 'orderInput'];

  static values = {
    minItems: Number,
    reorderPath: String,
  };

  static classes = ['ghost'];

  connect() {
    this.initializeSortable();
    this.currentIndex = this.sortableItemTargets.length;
  }

  initializeSortable() {
    this.sortable = new Sortable(this.sortableListTarget, {
      animation: 150,
      ghostClass: this.ghostClass,
      onEnd: () => {
        this.handleDragEnd();
      },
    });
  }

  addItem(event) {
    event.preventDefault();
    const templateContent = document.getElementById('item-template').content;
    let newItem = document.importNode(templateContent, true);

    this.currentIndex += 1;
    const tempDiv = document.createElement('div');
    tempDiv.appendChild(newItem);
    tempDiv.innerHTML = tempDiv.innerHTML
      .replace(/__INDEX__/g, this.currentIndex);

    [newItem] = tempDiv.children;

    this.sortableListTarget.appendChild(newItem);

    this.element.dispatchEvent(new CustomEvent('itemAdded', { bubbles: true }));
  }

  deleteItem(event) {
    event.preventDefault();

    if (this.minItemsValue && this.minItemsValue > 0) {
      const remainingItems = this.sortableListTarget.querySelectorAll('li').length;
      if (remainingItems === 1) {
        return;
      }
    }

    const item = this.sortableListTarget.querySelector(`[data-id="${event.params.id}"]`);
    if (item) {
      item.remove();

      this.element.dispatchEvent(new CustomEvent('itemRemoved', { bubbles: true }));
    }
  }

  handleDragEnd() {
    if (!this.reorderPathValue) {
      return;
    }

    const formData = new FormData();

    this.sortableItemTargets.forEach((item, index) => {
      const { id } = item.dataset;
      formData.append(`positions[${id}]`, index + 1);
    });

    patch(this.reorderPathValue, {
      body: formData,
    }).catch(() => {});
  }
}
