import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['rightSpan', 'boundingBox', 'bar', 'scrollLeft', 'scrollRight', 'scrollLeftButton', 'scrollRightButton'];

  static values = {
    scrollPosition: {
      type: String,
      default: 'left',
    },
    filter: Boolean,
  };

  connect() {
    // When the window is resized, the width of the right span element is updated
    this.setRightSpanWidth();
    window.addEventListener('resize', this.setRightSpanWidth.bind(this));

    // If the scroll bar is not needed, the scroll buttons are hidden
    if (!this.scrollBarNeeded()) {
      this.scrollLeftButtonTarget.style.display = 'none';
      this.scrollRightButtonTarget.style.display = 'none';
    // If the end of the scroll bar is reached, the scroll buttons are disabled
    } else {
      if (this.scrolledToTheLeft() && this.scrollBarNeeded()) {
        this.scrollLeftButtonTarget.disabled = true;
      }
      if (this.scrolledToTheRight() && this.scrollBarNeeded()) {
        this.scrollRightButtonTarget.disabled = true;
      }
    }
  }

  disconnect() {
    window.removeEventListener('resize', this.setRightSpanWidth);
  }

  scrollPositionValueChanged = () => {
    if (this.scrollPositionValue === 'right') {
      this.scrollRightButtonTarget.disabled = true;
    } else if (this.scrollPositionValue === 'left') {
      this.scrollLeftButtonTarget.disabled = true;
    } else {
      this.scrollLeftButtonTarget.disabled = false;
      this.scrollRightButtonTarget.disabled = false;
    }
  };

  scrollRight = () => {
    // Adds half the width of the bounding box to the current scroll position
    this.scroll((a, b) => a + b);
  };

  scrollLeft = () => {
    // Subtracts half the width of the bounding box from the current scroll position.
    this.scroll((a, b) => a - b);
  };

  scroll = (leftOrRight) => this.barTarget.scrollTo({
    // Calls the scroll method with the current scroll position
    // and 80% of the width of the bounding box
    left: leftOrRight(this.barTarget.scrollLeft, this.boundingBoxTarget.offsetWidth * 0.8),
    behavior: 'smooth',
  });

  watchScrollPosition = () => {
    //
    if (this.scrolledToTheRight()) {
      this.scrollPositionValue = 'right';
    } else if (this.scrolledToTheLeft()) {
      this.scrollPositionValue = 'left';
    } else {
      this.scrollPositionValue = 'middle';
    }
  };

  setRightSpanWidth() {
    const bodyWidth = document.body.clientWidth;
    const boundingBoxStyles = window.getComputedStyle(this.boundingBoxTarget);
    const boundingBoxWidth = this.boundingBoxTarget.clientWidth
       - parseFloat(boundingBoxStyles.paddingLeft) - parseFloat(boundingBoxStyles.paddingRight);

    if (bodyWidth > boundingBoxWidth) {
      const newWidth = `${(bodyWidth - boundingBoxWidth) / 2}px`;

      this.rightSpanTarget.style.width = newWidth;
      this.rightSpanTarget.style.minWidth = newWidth;
      this.rightSpanTarget.style.maxWidth = newWidth;
    } else {
      this.rightSpanTarget.style.width = '0px';
      this.rightSpanTarget.style.minWidth = '0px';
      this.rightSpanTarget.style.maxWidth = '0px';
    }
  }

  scrolledToTheRight = () => this.barTarget.scrollLeft >= this.scrollEnd() - 1
    && this.barTarget.scrollLeft <= this.scrollEnd() + 1;

  scrolledToTheLeft = () => this.barTarget.scrollLeft === 0;

  scrollBarNeeded = () => this.barTarget.scrollWidth > this.barTarget.offsetWidth;

  scrollEnd = () => this.barTarget.scrollWidth - this.barTarget.offsetWidth;
}
