import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['deleteButton'];

  static values = {
    deleteWord: String,
  };

  checkSecurityWord = (event) => {
    const deleteWord = this.deleteWordValue.toLowerCase();

    if (deleteWord === event.currentTarget.value.toLowerCase()) {
      if (this.deleteButtonTarget.disabled) {
        this.deleteButtonTarget.disabled = false;
        this.deleteButtonTarget.setAttribute('aria-disabled', false);
      }
    } else if (!this.deleteButtonTarget.disabled) {
      this.deleteButtonTarget.disabled = true;
      this.deleteButtonTarget.setAttribute('aria-disabled', true);
    }
  };
}
