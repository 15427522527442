import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['message', 'messagesOverflowContainer'];

  static values = {
    userUuid: String,
  };

  connect() {
    this.messagesOverflowContainerTarget
      .scrollTop = this.messagesOverflowContainerTarget.scrollHeight;
  }

  messageTargetConnected(element) {
    const deletionForm = element.querySelector('.message-deletion-form');

    if (deletionForm !== null) {
      const { authorUuid } = element.dataset;

      if (authorUuid !== this.userUuidValue) {
        deletionForm.remove();
      }
    }

    element.scrollIntoView();
  }
}
