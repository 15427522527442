import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static targets = ['trigger', 'dropdown'];

  connect() {
    window.addEventListener('keydown', this.handleKeydown.bind(this));
    useClickOutside(this, { element: this.dropdownTarget });
  }

  disconnect() {
    window.removeEventListener('keydown', this.handleKeydown);
  }

  clickOutside(event) {
    if (this.triggerTarget.contains(event.target)) return;
    this.closeDropdown();
  }

  toggleDropdown() {
    const isOpened = this.triggerTarget.getAttribute('aria-expanded') === 'true';

    this.triggerTarget.setAttribute('aria-expanded', !isOpened);
    this.dropdownTarget.classList.toggle('hidden');

    if (!isOpened) {
      this.dropdownTarget.querySelector("[role='menuitem']").focus();
    }
  }

  closeDropdown() {
    this.triggerTarget.setAttribute('aria-expanded', false);
    this.dropdownTarget.classList.add('hidden');
  }

  handleKeydown(event) {
    if (event.key === 'Escape') {
      this.closeDropdown();
      this.triggerTarget.focus();
    }

    if (this.dropdownTarget.classList.contains('hidden')) return;

    const focusableItems = Array.from(this.dropdownTarget.querySelectorAll("[role='menuitem']"));
    const currentIndex = focusableItems.indexOf(document.activeElement);

    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault();
        const nextIndex = (currentIndex + 1) % focusableItems.length;
        focusableItems[nextIndex].focus();
        break;
      }
      case 'ArrowUp': {
        event.preventDefault();
        const prevIndex = (currentIndex - 1 + focusableItems.length) % focusableItems.length;
        focusableItems[prevIndex].focus();
        break;
      }
      case 'Enter':
      case 'Space': {
        document.activeElement.click();
        break;
      }
      default:
        break;
    }
  }
}
