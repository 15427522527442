import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['floatingSubmit'];

  connect() {
    ['change', 'input', 'paste', 'trix-attachment-add', 'trix-attachment-remove'].forEach((event) => {
      this.element.addEventListener(event, this.displaySubmitBanner.bind(this));
    });
  }

  displaySubmitBanner() {
    this.floatingSubmitTarget.dataset.visible = true;
  }
}
