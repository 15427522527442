import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['label', 'input'];

  connect() {
    this.labelTarget.addEventListener('keydown', this.onEnter.bind(this));
  }

  disconnect() {
    this.labelTarget.removeEventListener('keydown', this.onEnter);
  }

  onEnter(event) {
    if (event.code !== 'Enter') return;

    event.preventDefault();

    if (!this.isFocused()) return;
    this.selectFiles();
  }

  isFocused() {
    return this.labelTarget === document.activeElement;
  }

  selectFiles() {
    this.inputTarget.click();
  }

  submit() {
    this.element.requestSubmit();
  }
}
