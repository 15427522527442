import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'container',
    'removeIconTemplate',
    'addIconTemplate',
    'toggleOptionsButton',
    'optionsList',
    'selectedList',
  ];

  static classes = [
    'hidden',
    'candidateOption',
    'selectedListElement',
    'selectedTextAndButtonWrapper',
    'selectedTextWrapper',
    'selectedText',
    'selectedButtonWrapper',
    'selectedButton',
  ];

  static values = {
    formObject: String,
    formAttribute: String,
    noMoreOptions: String,
    addOption: String,
  };

  connect = () => {
    document.addEventListener('click', this.hideOptionsListWithOutsideClick);
    document.addEventListener('keydown', this.switchOptionElementsWithArrowKeys);
    document.addEventListener('keydown', this.selectOptionElementWithEnter);
    document.addEventListener('keydown', this.hideOptionsListWithEscape);
    if (this.allOptionsSelected()) {
      this.disableToggleOptionsButton();
    }
  };

  disconnect = () => {
    document.removeEventListener('click', this.hideOptionsListWithOutsideClick);
    document.removeEventListener('keydown', this.switchOptionElementsWithArrowKeys);
    document.removeEventListener('keydown', this.selectOptionElementWithEnter);
    document.removeEventListener('keydown', this.hideOptionsListWithEscape);
  };

  hideOptionsListWithOutsideClick = (event) => {
    if (!this.toggleOptionsButtonTarget.contains(event.target)) {
      this.hideOptionsList();
    }
  };

  hideOptionsListWithEscape = (event) => {
    if (this.containEventAndCheckFocus(event, ['Escape'])) {
      this.hideOptionsList();
    }
  };

  hideOptionsList = () => {
    if (this.optionsListIsVisible()) {
      const candidateOption = this.getCandidateOption();
      if (candidateOption) {
        candidateOption.classList.remove(this.candidateOptionClass);
      }
      this.optionsListTarget.classList.add(this.hiddenClass);
    }
  };

  // handles the keyboard arrow navigation for the options list
  switchOptionElementsWithArrowKeys = (event) => {
    if (this.containEventAndCheckFocus(event, ['ArrowDown', 'ArrowUp'])) {
      this.showOptionsList();
      let candidateOption = this.getCandidateOption();
      if (candidateOption) {
        candidateOption.classList.remove(this.candidateOptionClass);
        if (event.code === 'ArrowDown') {
          this.selectNextCandidateOption(candidateOption);
        } else if (event.code === 'ArrowUp') {
          this.selectPreviousCandidateOption(candidateOption);
        }
      } else {
        candidateOption = this.optionsListTarget.firstElementChild;
        candidateOption.classList.add(this.candidateOptionClass);
      }
    }
  };

  selectNextCandidateOption = (candidateOption) => {
    const nextCandidate = this.visibleElementSibling(candidateOption, 'nextElementSibling');
    nextCandidate.scrollIntoView({ block: 'center' });
    nextCandidate.classList.add(this.candidateOptionClass);
  };

  selectPreviousCandidateOption = (candidateOption) => {
    const previousCandidate = this.visibleElementSibling(candidateOption, 'previousElementSibling');
    previousCandidate.scrollIntoView({ block: 'center' });
    previousCandidate.classList.add(this.candidateOptionClass);
  };

  // finds the next visible candidate option recursively
  visibleElementSibling = (candidateOption, siblingGetter) => {
    let directSibling = candidateOption[siblingGetter];
    if (!directSibling) {
      directSibling = siblingGetter === 'nextElementSibling' ? candidateOption.parentElement.firstElementChild : candidateOption.parentElement.lastElementChild;
    }
    if (directSibling.classList.contains(this.hiddenClass)) {
      return this.visibleElementSibling(directSibling, siblingGetter);
    }
    return directSibling;
  };

  selectOptionElementWithEnter = (event) => {
    if (this.containEventAndCheckFocus(event, ['Enter'])) {
      const candidateOption = this.getCandidateOption();
      if (candidateOption) {
        this.selectOption(this.getCandidateOption());
        this.toggleOptionsList();
      }
    }
  };

  getCandidateOption = () => this.optionsListTarget.querySelector(`li.${this.candidateOptionClass}`);

  containEventAndCheckFocus = (event, expectedCodes) => {
    if (expectedCodes.includes(event.code)
      && this.toggleOptionsButtonTarget === document.activeElement) {
      event.preventDefault();
      return true;
    }
    return false;
  };

  // hides and shows the options list
  toggleOptionsList = () => {
    const candidateOption = this.getCandidateOption();
    if (candidateOption) {
      candidateOption.classList.remove(this.candidateOptionClass);
    }
    this.optionsListTarget.classList.toggle(this.hiddenClass);
  };

  showOptionsList = () => this.optionsListTarget.classList.remove(this.hiddenClass);

  selectOptionByClick = (event) => {
    this.selectOption(event.currentTarget);
  };

  // performs the steps to select an option from the options list
  selectOption = (candidateOption) => {
    const optionValue = candidateOption.getAttribute('data-value');
    this.addSelectedElementToList(candidateOption.querySelector('span').innerHTML, optionValue);
    this.addSelectedInputValue(optionValue);
    this.toggleOption(this.getElementIdentifier(optionValue));
    if (this.allOptionsSelected()) {
      this.disableToggleOptionsButton();
    }
  };

  // performs the selection step to add the selected element to the list of selections
  addSelectedElementToList = (optionText, optionValue) => {
    const listElement = document.createElement('li');
    listElement.classList.add(...this.selectedListElementClasses);
    listElement.setAttribute('data-identifier', this.getElementIdentifier(optionValue));

    const buttonAndTextWrapper = document.createElement('div');
    buttonAndTextWrapper.classList.add(...this.selectedTextAndButtonWrapperClasses);

    const textWrapper = document.createElement('div');
    textWrapper.classList.add(...this.selectedTextWrapperClasses);

    const selectedElement = document.createElement('span');
    selectedElement.classList.add(...this.selectedTextClasses);
    selectedElement.innerHTML = optionText;

    const buttonWrapper = document.createElement('div');
    buttonWrapper.classList.add(...this.selectedButtonWrapperClasses);

    const button = document.createElement('button');
    button.classList.add(...this.selectedButtonClasses);
    button.onclick = this.removeSelection;

    const icon = this.removeIconTemplateTarget.cloneNode(true);
    icon.classList.remove(this.hiddenClass);

    this.selectedListTarget.appendChild(listElement);
    listElement.appendChild(buttonAndTextWrapper);
    buttonAndTextWrapper.appendChild(textWrapper).appendChild(selectedElement);
    buttonAndTextWrapper.appendChild(buttonWrapper).appendChild(button);
    button.appendChild(icon);
  };

  // performs the selection step to add the input field of the selection to the form
  addSelectedInputValue = (value) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('id', this.getElementIdentifier(value));
    input.setAttribute('name', `${this.formObjectValue}[${this.formAttributeValue}][]`);
    input.value = value;
    this.containerTarget.prepend(input);
    input.dispatchEvent(new Event('change', { bubbles: true }));
  };

  // disables the button for toggling the options list; invoked when all options are selected
  disableToggleOptionsButton = () => {
    this.toggleOptionsButtonTarget.disabled = true;
    this.toggleOptionsButtonTarget.setAttribute('aria-disabled', true);
    this.toggleOptionsButtonTarget.innerText = this.noMoreOptionsValue;
  };

  // performs the steps to remove a selection from the selections list
  removeSelection = (event) => {
    const listElement = event.currentTarget.closest('li');
    if (event.type === 'keydown' && document.activeElement !== event.currentTarget) {
      return;
    }
    listElement.dispatchEvent(new Event('change', { bubbles: true }));
    this.removeSelectionFromList(listElement);
    this.removeInputField(listElement.getAttribute('data-identifier'));
    this.toggleOption(listElement.getAttribute('data-identifier'));
    if (this.toggleOptionsButtonTarget.disabled === true) {
      this.enableToggleOptionsButton();
    }
  };

  // performs the removal step to remove the selected element from the list of selections
  removeSelectionFromList = (element) => element.remove();

  // performs the removal step to remove the input field for the selection from the form
  removeInputField = (id) => document.getElementById(id).remove();

  // removes and adds an option from the list of options when it is selected or unselected
  toggleOption = (identifier) => {
    const option = this.optionsListTarget.querySelector(`[data-identifier='${identifier}']`);
    option.classList.toggle(this.hiddenClass);
  };

  // enables the button for toggling the options list; invoked when some options or not selected
  enableToggleOptionsButton = () => {
    this.toggleOptionsButtonTarget.disabled = false;
    this.toggleOptionsButtonTarget.setAttribute('aria-disabled', false);
    this.toggleOptionsButtonTarget.innerText = this.addOptionValue;
    const icon = this.addIconTemplateTarget.cloneNode(true);
    icon.classList.remove(this.hiddenClass);
    this.toggleOptionsButtonTarget.prepend(icon);
  };

  optionsListIsVisible = () => !this.optionsListTarget.classList.contains(this.hiddenClass);

  getElementIdentifier = (value) => `${this.formObjectValue}_${this.formAttributeValue}_${value}`;

  allOptionsSelected = () => [...this.optionsListTarget.children]
    .every((option) => option.classList.contains(this.hiddenClass));
}
