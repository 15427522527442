import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['options', 'selectedElement', 'input', 'focus', 'container'];

  static classes = ['hidden', 'selected'];

  connect() {
    const preSelectedElement = this.optionsTarget.querySelector(`[data-value="${this.inputTarget.value}"`);
    preSelectedElement.classList.add(this.selectedClass);
    this.focusTarget.addEventListener('keydown', this.switchOptionElementsWithArrowKeys);
    this.focusTarget.addEventListener('keydown', this.selectOptionElementWithEnter);
    this.focusTarget.addEventListener('keydown', this.hideOptionsListWithEscape);
    document.addEventListener('click', this.hideOptionsListWithOutsideClick);
  }

  disconnect() {
    this.focusTarget.removeEventListener('keydown', this.switchOptionElementsWithArrowKeys);
    this.focusTarget.removeEventListener('keydown', this.selectOptionElementWithEnter);
    this.focusTarget.removeEventListener('keydown', this.hideOptionsListWithEscape);
    document.removeEventListener('click', this.hideOptionsListWithOutsideClick);
  }

  hideOptionsListWithOutsideClick = (event) => {
    if (!this.containerTarget.contains(event.target)) {
      if (this.optionsListIsVisible()) {
        this.optionsTarget.classList.add(this.hiddenClass);
      }
    }
  };

  hideOptionsListWithEscape = (event) => {
    if (this.containEventAndCheckFocus(event, ['Escape'])) {
      this.optionsTarget.classList.add(this.hiddenClass);
    }
  };

  selectOptionElementWithEnter = (event) => {
    if (this.containEventAndCheckFocus(event, ['Enter'])) {
      this.selectOption(this.getSelectedOptionElement());
    }
  };

  switchOptionElementsWithArrowKeys = (event) => {
    if (this.containEventAndCheckFocus(event, ['ArrowDown', 'ArrowUp'])) {
      this.optionsTarget.classList.remove(this.hiddenClass);
      const alreadySelectedElement = this.getSelectedOptionElement();
      alreadySelectedElement.classList.remove(this.selectedClass);
      if (event.code === 'ArrowDown') {
        this.selectNextElement(alreadySelectedElement);
      } else if (event.code === 'ArrowUp') {
        this.selectPreviousElement(alreadySelectedElement);
      }
    }
  };

  selectNextElement = (alreadySelectedElement) => {
    const nextElement = this.isLastChild(alreadySelectedElement)
      ? alreadySelectedElement.parentElement.firstElementChild
      : alreadySelectedElement.nextElementSibling;
    nextElement.classList.add(this.selectedClass);
  };

  selectPreviousElement = (alreadySelectedElement) => {
    const previousElement = this.isFirstChild(alreadySelectedElement)
      ? alreadySelectedElement.parentElement.lastElementChild
      : alreadySelectedElement.previousElementSibling;
    previousElement.classList.add(this.selectedClass);
  };

  getSelectedOptionElement = () => this.optionsTarget.querySelector(`li.${this.selectedClass}`);

  containEventAndCheckFocus = (event, expectedCodes) => {
    if (expectedCodes.includes(event.code) && this.focusTarget === document.activeElement) {
      event.preventDefault();
      return true;
    }
    return false;
  };

  toggleOptionsList = () => {
    this.focusTarget.focus();
    this.optionsTarget.classList.toggle(this.hiddenClass);
  };

  selectOptionByClick = (event) => {
    this.selectOption(event.currentTarget);
  };

  selectOption = (selectedOption) => {
    this.showSelectedElement(selectedOption.firstElementChild.cloneNode(true));
    this.prepareAndHideOptionsList(selectedOption);
    const oldValue = this.inputTarget.value;
    this.inputTarget.value = selectedOption.getAttribute('data-value');
    if (this.inputTarget.value !== oldValue) {
      const event = new CustomEvent('selectInputValueChanged', {
        detail: { newValue: this.inputTarget.value, inputId: this.inputTarget.id },
        bubbles: true,
      });
      this.inputTarget.dispatchEvent(event);
    }
    this.focusTarget.focus();
  };

  showSelectedElement = (selectedElement) => {
    this.selectedElementTarget.replaceChild(
      selectedElement,
      this.selectedElementTarget.firstElementChild,
    );
  };

  prepareAndHideOptionsList = (selectedElement) => {
    this.optionsTarget.querySelectorAll('li').forEach((option) => option.classList.remove(this.selectedClass));
    selectedElement.classList.add(this.selectedClass);
    this.optionsTarget.classList.add(this.hiddenClass);
  };

  optionsListIsVisible = () => !this.optionsTarget.classList.contains(this.hiddenClass);

  isFirstChild = (element) => element.previousElementSibling == null;

  isLastChild = (element) => element.nextElementSibling == null;
}
