import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['hiddenField', 'switch'];

  connect() {
    this.setSwitchState(this.hiddenFieldTarget.value === 'true');
  }

  toggle() {
    const enabled = this.hiddenFieldTarget.value === 'false';
    this.hiddenFieldTarget.value = enabled ? 'true' : 'false';
    this.setSwitchState(enabled);
    this.hiddenFieldTarget.dispatchEvent(new Event('change', {
      bubbles: true,
      detail: { value: this.hiddenFieldTarget.value },
    }));
    this.emitEvent(enabled);
  }

  setSwitchState(enabled) {
    this.switchTarget.setAttribute('aria-checked', enabled);
    this.switchTarget.classList.toggle('bg-blue-600', enabled);
    this.switchTarget.classList.toggle('disabled:bg-blue-200', enabled);
    this.switchTarget.classList.toggle('bg-gray-200', !enabled);
    this.switchTarget.classList.toggle('disabled:bg-gray-100', !enabled);
    const knob = this.switchTarget.querySelector('span');
    if (knob) {
      knob.classList.toggle('translate-x-5', enabled);
      knob.classList.toggle('translate-x-0', !enabled);
    }
  }

  emitEvent(enabled) {
    this.element.dispatchEvent(new CustomEvent('toggleValueChanged', {
      detail: { value: enabled, inputId: this.hiddenFieldTarget.id },
      bubbles: true,
    }));
  }
}
