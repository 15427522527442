import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['changeQuestionType', 'optionRepresentation'];

  static values = {
    questionType: String,
    minOptions: Number,
  };

  connect() {
    this.element.addEventListener('selectInputValueChanged', this.switchQuestionType);
    if (this.questionTypeValue === 'MultipleChoiceSurveyQuestion' || this.questionTypeValue === 'SingleChoiceSurveyQuestion') {
      this.element.addEventListener('itemAdded', this.updateDeleteButtonVisibility);
      this.element.addEventListener('itemRemoved', this.updateDeleteButtonVisibility);
      this.updateDeleteButtonVisibility();
    }
  }

  disconnect() {
    this.element.removeEventListener('selectInputValueChanged', this.switchQuestionType);
    if (this.questionTypeValue === 'MultipleChoiceSurveyQuestion' || this.questionTypeValue === 'SingleChoiceSurveyQuestion') {
      this.element.removeEventListener('itemAdded', this.updateDeleteButtonVisibility);
      this.element.removeEventListener('itemRemoved', this.updateDeleteButtonVisibility);
    }
  }

  switchQuestionType = (event) => {
    if (event.detail.inputId === 'survey_question_type') {
      this.changeQuestionTypeTarget.click();
    }
  };

  updateDeleteButtonVisibility = () => {
    const items = this.element.querySelectorAll('[data-test="list-row"]');

    this.element.querySelectorAll('[data-test="delete-survey-question-option-button"]').forEach((button) => {
      button.classList.toggle('hidden', (items.length <= this.minOptionsValue));
    });
  };
}
