import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['localeOverlay', 'menuItem'];

  static values = {
    activeClass: String,
  };

  updateOverlay(event) {
    this.localeOverlayTarget.textContent = event.params.locale;
  }

  switchLocaleInput(event) {
    this.element.querySelectorAll('[data-locale-input]')
      .forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.hidden = element.getAttribute('data-locale-input') !== event.params.locale;
      });

    this.menuItemTargets.forEach((element) => {
      if (element.getAttribute(`data-${this.identifier}-locale-param`) === event.params.locale) {
        element.classList.add(this.activeClassValue);
      } else {
        element.classList.remove(this.activeClassValue);
      }
    });
  }
}
