import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tab', 'panel'];

  get selectedClasses() {
    return this.element.getAttribute('data-selected-classes').split(' ');
  }

  get unselectedClasses() {
    return this.element.getAttribute('data-unselected-classes').split(' ');
  }

  connect() {
    window.addEventListener('keydown', this.handleKeydown.bind(this));
  }

  disconnect() {
    window.addEventListener('keydown', this.handleKeydown);
  }

  handleKeydown(event) {
    if (event.code === 'Left') this.onPressLeft();
    if (event.code === 'Right') this.onPressRight();
    if (event.code === 'Home') this.onPressHome();
    if (event.code === 'End') this.onPressEnd();
  }

  filter(event) {
    return this.tabTargets.includes(event.target);
  }

  onPressLeft(event) {
    if (!this.filter(event)) return;
    this.moveFocus(event, -1);
  }

  onPressRight(event) {
    if (!this.filter(event)) return;
    this.moveFocus(event, 1);
  }

  onPressHome(event) {
    if (!this.filter(event)) return;
    event.preventDefault();
    this.tabTargets[0].focus();
  }

  onPressEnd(event) {
    if (!this.filter(event)) return;
    const tabs = this.tabTargets;
    event.preventDefault();
    tabs[tabs.length - 1].focus();
  }

  moveFocus(event, direction) {
    event.preventDefault();
    const tabs = this.tabTargets;
    const index = tabs.indexOf(document.activeElement);
    const newIndex = (index + direction + tabs.length) % tabs.length;
    tabs[newIndex].focus();
  }

  selectTab(event) {
    const selectedTab = event.target;
    const otherTabs = this.tabTargets.filter((tab) => tab !== selectedTab);
    const selectedPanel = document.getElementById(selectedTab.getAttribute('aria-controls'));
    const otherPanels = this.panelTargets.filter((panel) => panel !== selectedPanel);

    selectedTab.setAttribute('aria-selected', true);
    selectedTab.removeAttribute('tabindex');
    selectedTab.classList.remove(...this.unselectedClasses);
    selectedTab.classList.add(...this.selectedClasses);
    selectedPanel.removeAttribute('hidden');

    otherTabs.forEach((tab) => {
      tab.setAttribute('aria-selected', false);
      tab.setAttribute('tabindex', '-1');
      tab.classList.remove(...this.selectedClasses);
      tab.classList.add(...this.unselectedClasses);
    });
    otherPanels.forEach((panel) => panel.setAttribute('hidden', true));
  }
}
