import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['placeholder', 'input', 'inputWrapper', 'hiddenInput'];

  connect() {
    this.inputTarget.addEventListener('keydown', this.onEnter.bind(this));
    this.inputTarget.focus();

    this.updateElements();
  }

  disconnect() {
    this.inputTarget.removeEventListener('keydown', this.onEnter);
  }

  updateElements() {
    if (this.getCleanInnerText().length > 0) {
      this.placeholderTarget.classList.add('hidden');
    } else {
      this.placeholderTarget.classList.remove('hidden');
    }
  }

  onInputPillClick() {
    this.inputTarget.focus();
  }

  onPaste(event) {
    event.preventDefault();
    const text = event.clipboardData?.getData('text/plain');
    window.document.execCommand('insertText', false, text);
  }

  onInput() {
    this.updateElements();
    this.hiddenInputTarget.value = this.getCleanInnerText();
  }

  onEnter(event) {
    if (event.shiftKey) return;
    if (event.code !== 'Enter') return;
    if (event.target !== this.inputTarget) return;

    event.preventDefault();
    this.submit();
  }

  onSubmit(event) {
    event.preventDefault();
    this.submit();
  }

  submit() {
    this.element.requestSubmit();
    this.inputTarget.innerText = '';
    this.hiddenInputTarget.value = '';
    this.updateElements();
  }

  // Removes all non-printable ASCII characters
  // This is necessary, because otherwise a backspace character
  // would be inserted when removing all text, making it != ''
  getCleanInnerText() {
    const text = this.inputTarget.innerText;

    if (text.charCodeAt(0) === 10 && text.length === 1) {
      return '';
    }
    return text ?? '';
  }
}
