import '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';
import { registerControllers } from 'stimulus-vite-helpers';

import '../globals/break-frames';
import '../components/generic/sidebar_layout_component';

import '../utils/iFrameHelper';

import { application } from '../controllers/application';

ActiveStorage.start();

const controllers = import.meta.glob('../../components/**/*.js', { eager: true });
registerControllers(application, controllers);
