import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  select(event) {
    const url = new URL(event.target.dataset.url);
    url.searchParams.set('map_type', event.target.value);

    Turbo.visit(url, { frame: 'risk-assessments-heatmap-results' });
  }
}
