import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  onClick(event) {
    if (this.isActionButton(event.target)) {
      return;
    }

    if (event.metaKey || event.ctrlKey) {
      window.open(this.element.dataset.url, '_blank');
    } else {
      window.location.href = this.element.dataset.url;
    }
  }

  isActionButton(element) {
    if (element.tagName === 'BUTTON' || element.tagName === 'A') {
      return true;
    }

    while (element.parentElement) {
      // eslint-disable-next-line no-param-reassign
      element = element.parentElement;
      if (element.tagName === 'BUTTON' || element.tagName === 'A') {
        return true;
      }
    }

    return false;
  }
}
