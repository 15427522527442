import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  paramName = 'f_topics[]';

  static targets = ['bar', 'topicButton', 'scrollLeft', 'scrollRight'];

  static classes = ['selected', 'unselected', 'visible'];

  static values = {
    scrollPosition: {
      type: String,
      default: 'left',
    },
    filter: Boolean,
  };

  connect() {
    this.initializeClasses();

    if (this.filterValue) {
      this.topicButtonTargets.forEach((button) => {
        button.addEventListener('click', this.applyFilter, true);
      });
    }

    this.topicButtonTargets.forEach((button) => {
      if (this.isSelected(button)) {
        this.barTarget.scrollTo({
          left: button.offsetLeft - button.offsetWidth,
        });
      }
    });

    if (this.scrolledToTheRight() && this.scrollBarNeeded()) {
      this.scrollLeftTarget.classList.add(this.visibleClass);
    }

    if (this.scrolledToTheLeft() && this.scrollBarNeeded()) {
      this.scrollRightTarget.classList.add(this.visibleClass);
    }
  }

  initializeClasses = () => {
    let preselectedTopicExists = false;

    this.topicButtonTargets.forEach((button) => {
      if (this.isPreselected(button)) {
        this.selectButton(button);
        preselectedTopicExists = true;
      } else {
        this.unselectButton(button);
      }
    });

    if (!preselectedTopicExists) {
      this.selectButton(this.allTopicsButton());
    }
  };

  applySelectedClasses(event) {
    this.topicButtonTargets.forEach((button) => {
      if (this.isSelected(button)) {
        this.unselectButton(button);
      }
    });

    this.selectButton(event.target);
  }

  applyFilter = (event) => {
    const url = this.url();
    url.searchParams.delete(this.paramName);

    if (event.target.hasAttribute('data-topic-id')) {
      url.searchParams.append(this.paramName, event.target.getAttribute('data-topic-id'));
    }

    event.target.setAttribute('href', url.toString());
    window.history.pushState({}, '', url.toString());
  };

  isSelected = (button) => this.selectedClasses.every((c) => button.classList.contains(c));

  isPreselected = (button) => {
    let isPreselected;

    if (this.filterValue) {
      isPreselected = this.url().searchParams.has(this.paramName, button.getAttribute('data-topic-id'));
    } else {
      isPreselected = button.getAttribute('data-preselected') === 'true';
    }
    return isPreselected;
  };

  selectButton = (button) => {
    button.setAttribute('aria-current', 'page');
    button.classList.add(...this.selectedClasses);
    button.classList.remove(...this.unselectedClasses);
  };

  unselectButton = (button) => {
    button.removeAttribute('aria-current');
    button.classList.add(...this.unselectedClasses);
    button.classList.remove(...this.selectedClasses);
  };

  allTopicsButton = () => this.topicButtonTargets[0];

  url = () => new URL(window.location.href);

  scrollPositionValueChanged = () => {
    if (this.scrollPositionValue === 'right') {
      this.scrollRightTarget.classList.remove(this.visibleClass);
    } else if (this.scrollPositionValue === 'left') {
      this.scrollLeftTarget.classList.remove(this.visibleClass);
    } else {
      this.scrollRightTarget.classList.add(this.visibleClass);
      this.scrollLeftTarget.classList.add(this.visibleClass);
    }
  };

  scrollRight = () => {
    this.scroll((a, b) => a + b);
  };

  scrollLeft = () => {
    this.scroll((a, b) => a - b);
  };

  scroll = (leftOrRight) => this.barTarget.scrollTo({
    left: leftOrRight(this.barTarget.scrollLeft, this.barTarget.offsetWidth / 2),
    behavior: 'smooth',
  });

  watchScrollPosition = () => {
    if (this.scrolledToTheRight()) {
      this.scrollPositionValue = 'right';
    } else if (this.scrolledToTheLeft()) {
      this.scrollPositionValue = 'left';
    } else {
      this.scrollPositionValue = 'middle';
    }
  };

  scrolledToTheRight = () => this.barTarget.scrollLeft >= this.scrollEnd() - 1
    && this.barTarget.scrollLeft <= this.scrollEnd() + 1;

  scrolledToTheLeft = () => this.barTarget.scrollLeft === 0;

  scrollBarNeeded = () => this.barTarget.scrollWidth > this.barTarget.offsetWidth;

  scrollEnd = () => this.barTarget.scrollWidth - this.barTarget.offsetWidth;
}
