import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static values = { filterPath: String };

  static targets = ['filterButton'];

  connect() {
    this.filterButtonTarget.addEventListener('click', this.openFilterDropdown);
    window.addEventListener('keydown', this.handleKeydown.bind(this));
  }

  openFilterDropdown = () => {
    const wrapper = document.getElementById('risk-assessment-filter');
    const children = wrapper.querySelector('div');

    if (children) {
      if (children.style.display === 'none') {
        children.style.display = 'block';
      } else if (children.dataset.filterAttribute === 'true') {
        children.remove();
      } else {
        children.style.display = 'none';
      }
    } else {
      const url = new URL(this.filterPathValue);
      Turbo.visit(url, { frame: 'risk-assessment-filter' });
    }
  };

  selectFilterAttribute = (event) => {
    const currentUrl = new URL(window.location.href);
    const url = new URL(this.filterPathValue);
    const paramName = event.currentTarget.getAttribute('data-filter-attribute');
    const paramValue = event.currentTarget.getAttribute('data-filter-value');
    const customFrame = `risk-assessment-filter-${paramName.replace(/_/g, '-')}-${paramValue}`;

    const wrapper = document.getElementById(customFrame);
    const children = wrapper.querySelector('div');

    if (children) {
      if (children.style.display === 'none') {
        children.style.display = 'block';
      } else {
        children.style.display = 'none';
      }
    } else {
      url.searchParams.set('filter_attribute', paramName);

      currentUrl.searchParams.forEach((value, key) => {
        url.searchParams.append(key, value);
      });

      if (paramName === 'participant_group_ids') {
        url.searchParams.append('participant_group_ids[]', paramValue);
      } else {
        url.searchParams.append(paramName, paramValue);
      }

      url.searchParams.set('custom_frame', customFrame);

      Turbo.visit(url, { frame: customFrame });
    }
  };

  removeFilter = (event) => {
    const url = new URL(window.location.href);
    const paramName = event.currentTarget.getAttribute('data-filter-attribute');

    if (paramName === 'participant_group_ids') {
      url.searchParams.delete('participant_group_ids[]');
    } else {
      const paramValue = JSON.parse(event.currentTarget.getAttribute('data-filter-option-value'));

      paramValue.forEach((value) => {
        url.searchParams.delete('survey_question_option_ids[]', value);
      });
    }

    window.history.pushState({}, '', url.toString());
    window.location.href = url.toString();
  };

  handleKeydown(event) {
    if (event.code === 'Escape') {
      const wrapper = document.getElementById('risk-assessment-filter');
      const children = wrapper.querySelector('div');

      if (children && children.style.display === 'block') {
        children.style.display = 'none';
      }
    }
  }
}
