import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['category', 'result'];

  connect() {
    this.categoryTarget.addEventListener('change', this.handleSelect.bind(this));
  }

  disconnect() {
    this.categoryTarget.removeEventListener('change', this.handleSelect.bind(this));
  }

  handleSelect() {
    // Get the selected option value
    const selectedValue = this.categoryTarget.value;

    // Show only elements with selectedValue and hide the rest
    // If selectedValue is "all", show all elements
    this.resultTargets.forEach((element) => {
      if (selectedValue === 'all' || element.dataset.resultCategory === selectedValue) {
        element.classList.remove('hidden');
      } else {
        element.classList.add('hidden');
      }
    });
  }
}
