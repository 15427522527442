import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['submit', 'checkbox'];

  connect() {
    this.checkboxTarget.disabled = false;
  }

  toggleSubmitDisabled() {
    this.submitTarget.disabled = !this.submitTarget.disabled;
    this.submitTarget.setAttribute('aria-disabled', this.submitTarget.disabled);
  }
}
