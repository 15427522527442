import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['container'];

  static values = { count: Number };

  static classes = ['scaleLevel'];

  connect() {
    this.renderLikertLevels();
    document.addEventListener('selectInputValueChanged', this.switchScaleLevel);
  }

  disconnect() {
    document.removeEventListener('selectInputValueChanged', this.switchScaleLevel);
  }

  switchScaleLevel = (event) => {
    if (event.detail.inputId === 'survey_question_likert_scale_level') {
      this.countValue = event.detail.newValue;
      this.renderLikertLevels();
    }
  };

  renderLikertLevels() {
    const container = this.containerTarget;
    container.innerHTML = '';
    const maxBoxes = Math.min(this.countValue, 10);

    for (let i = 1; i <= maxBoxes; i += 1) {
      const box = document.createElement('div');
      box.classList.add(...this.scaleLevelClasses);
      box.innerText = i;
      container.appendChild(box);
    }
  }
}
