import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    playoutId: String,
    containerId: String,
    trackingUrl: String,
    resourceType: String,
    resourceId: String,
  };

  connect() {
    // eslint-disable-next-line no-new,new-cap,no-undef
    const player = new js3q({
      dataid: this.playoutIdValue,
      container: `${this.containerIdValue}`,
      initialQuality: 480,
      playbackRateMenu: true,
      tintColor: '#0066CC',
      chromecast: false,
    });

    let hasStartedPlaying = false;

    player.on('media.playing', () => {
      if (!hasStartedPlaying && this.trackingUrlValue) {
        hasStartedPlaying = true;
        this.trackPlayEvent();
      }
    });
  }

  async trackPlayEvent() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    await fetch(this.trackingUrlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify({
        resource_type: this.resourceTypeValue,
        resource_id: this.resourceIdValue,
        resource_action: 'play',
      }),
    });
  }
}
