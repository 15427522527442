import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['countdown', 'days', 'hours', 'minutes', 'seconds'];

  static values = { startAt: String };

  startTimer() {
    if (this.hasCountdownTarget) {
      this.timer = setInterval(() => {
        const countDownDate = new Date(this.startAtValue).getTime();
        const now = new Date().getTime();
        const distance = countDownDate - now;

        if (distance <= 0) {
          clearInterval(this.timer);
          this.countdownTarget.remove();
          window.location.reload();
          return;
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (days === 0 && hours === 0 && minutes === 9 && seconds === 59) {
          window.location.reload();
        }

        this.updateOrRemoveTarget('days', days, true);
        this.updateOrRemoveTarget('hours', hours, days === 0);
        this.updateOrRemoveTarget('minutes', minutes, days === 0 && hours === 0);
        this.updateOrRemoveTarget('seconds', seconds, days === 0 && hours === 0 && minutes === 0);
      }, 1000);
    }
  }

  updateOrRemoveTarget(targetName, value, canRemove) {
    if (this[`has${this.capitalize(targetName)}Target`]) {
      const targetElement = this[`${targetName}Target`];
      const numberElement = targetElement.querySelector('[data-timer="value"]');
      if (value === 0 && canRemove) {
        targetElement.remove();
      } else if (numberElement) {
        numberElement.textContent = value;
      }
    }
  }

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  connect() {
    this.startTimer();
  }

  disconnect() {
    this.stopTimer();
  }
}
