import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    document.addEventListener('selectInputValueChanged', this.redirectToValue);
  }

  disconnect() {
    document.removeEventListener('selectInputValueChanged', this.redirectToValue);
  }

  redirectToValue = (event) => {
    window.location.href = event.detail.newValue;
  };
}
