import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import Sortable, { AutoScroll } from 'sortablejs/modular/sortable.core.esm';

export default class extends Controller {
  static targets = ['column', 'count'];

  static classes = ['ghost'];

  connect() {
    Sortable.mount(new AutoScroll());

    this.sortables = [];

    this.columnTargets.forEach((column) => {
      const sortable = new Sortable(column, {
        group: 'measures',
        animation: 150,
        draggable: '[data-measure-position-update-path]',
        forceAutoScrollFallback: true,
        scroll: true,
        scrollSensitivity: 200,
        scrollSpeed: 25,
        bubbleScroll: true,
        onStart: (event) => {
          event.item.addEventListener('click', this.preventClick, true);
        },
        onEnd: (event) => {
          event.item.removeEventListener('click', this.preventClick, true);
          this.handleDragEnd(event);
        },
        ghostClass: this.ghostClass,
      });

      this.sortables.push(sortable);
    });
  }

  disconnect() {
    this.sortables.forEach((sortable) => {
      sortable.destroy();
    });
    this.sortables = [];
  }

  preventClick(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  handleDragEnd(event) {
    const positionUpdatePath = event.item.dataset.measurePositionUpdatePath;
    const newStatus = event.to.dataset.status;
    const { newIndex } = event;

    if (event.from !== event.to) {
      this.updateCounts(event.from);
      this.updateCounts(event.to);
    }

    const formData = new FormData();
    formData.append('status', newStatus);
    formData.append('position', newIndex);
    fetch(positionUpdatePath, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': this.getMetaValue('csrf-token'),
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'text/vnd.turbo-stream.html',
      },
      body: formData,
      credentials: 'same-origin',
    }).then(async (response) => {
      const contentType = response.headers.get('Content-Type') || '';
      if (contentType.includes('text/vnd.turbo-stream.html')) {
        const turboStream = await response.text();
        Turbo.renderStreamMessage(turboStream);
      }
    }).catch(() => {});
  }

  updateCounts(column) {
    const { status } = column.dataset;
    const countElement = this.countTargets.find((element) => element.dataset.status === status);
    if (countElement) {
      countElement.textContent = column.querySelectorAll('[data-measure-position-update-path]').length;
    }
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element && element.getAttribute('content');
  }
}
