import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    calcomUrl: String,
  };

  connect() {
    /* eslint-disable */
    (function (C, A, L) {
      let p = function (a, ar) {
        a.q.push(ar);
      };
      let d = C.document;
      C.Cal =
        C.Cal ||
        function () {
          let cal = C.Cal;
          let ar = arguments;
          if (!cal.loaded) {
            cal.ns = {};
            cal.q = cal.q || [];
            d.head.appendChild(d.createElement("script")).src = A;
            cal.loaded = true;
          }
          if (ar[0] === L) {
            const api = function () {
              p(api, arguments);
            };
            const namespace = ar[1];
            api.q = api.q || [];
            typeof namespace === "string"
              ? (cal.ns[namespace] = api) && p(api, ar)
              : p(cal, ar);
            return;
          }
          p(cal, ar);
        };
    })(window, `${this.calcomUrlValue}embed/embed.js`, "init");
    Cal("init", {
      origin: this.calcomUrlValue,
    });
    Cal("ui", {
      theme: "light",
      styles: { branding: { brandColor: "#0066cc" } },
      hideEventTypeDetails: true,
    });
    /* eslint-enable */
  }
}
