import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['past', 'future'];

  checkDate = (event) => {
    const today = this.beginningOf(new Date());
    const enteredDate = this.beginningOf(new Date(event.target.value));
    if (enteredDate < today) {
      this.pastTarget.dataset.visible = true;
    } else {
      this.pastTarget.dataset.visible = false;
    }

    const tooFarInFuture = today;
    tooFarInFuture.setDate(today.getDate() + 30);
    if (enteredDate > tooFarInFuture) {
      this.futureTarget.dataset.visible = true;
    } else {
      this.futureTarget.dataset.visible = false;
    }
  };

  beginningOf = (date) => {
    const beginning = new Date(date);
    beginning.setHours(0, 0, 0, 0);
    return beginning;
  };
}
