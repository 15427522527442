import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static targets = ['filterListOption', 'filterValue', 'saveButton', 'filterListWrapper'];

  static values = { filterPath: String, filterIdentifier: String };

  connect() {
    this.saveButtonTargets.forEach((button) => {
      button.addEventListener('click', this.applyFilters, true);
    });

    useClickOutside(this, { element: this.filterListWrapperTarget });
    window.addEventListener('keydown', this.handleKeydown.bind(this));
  }

  filterDropdownOptions = (element) => {
    const searchTerm = element.target.value;
    this.filterListOptionTargets.forEach((item) => {
      if (item.textContent.toLowerCase().includes(searchTerm.toLowerCase())) {
        item.style.display = 'block';
      } else {
        item.style.display = 'none';
      }
    });
  };

  clickOutside = (event) => {
    const parentElement = document.getElementById(this.filterIdentifierValue || 'filter-button');
    if (parentElement?.contains(event.target)) return;

    if (this.filterListWrapperTarget.getAttribute('data-filter-attribute') === 'true') {
      this.filterListWrapperTarget.remove();
    } else {
      this.filterListWrapperTarget.style.display = 'none';
    }
  };

  selectFilterAttribute = (element) => {
    const currentUrl = new URL(window.location.href);
    const url = new URL(this.filterPathValue);
    const paramName = element.target.getAttribute('data-filter-attribute');
    const paramValue = element.target.getAttribute('data-filter-value');

    url.searchParams.set('filter_attribute', paramName);

    currentUrl.searchParams.forEach((value, key) => {
      url.searchParams.append(key, value);
    });

    if (paramName === 'participant_group_ids') {
      url.searchParams.append('participant_group_ids[]', paramValue);
    } else {
      url.searchParams.append(paramName, paramValue);
    }

    Turbo.visit(url, { frame: 'risk-assessment-filter' });
  };

  applyFilters = (event) => {
    const url = new URL(window.location.href);

    this.filterValueTargets
      .forEach((checkbox) => {
        const paramName = `${checkbox.getAttribute('data-filter-attribute')}[]`;
        const paramValue = checkbox.id;
        if (checkbox.checked) {
          if (!url.searchParams.has(paramName, paramValue)) {
            url.searchParams.append(paramName, paramValue);
          }
        } else {
          url.searchParams.delete(paramName, paramValue);
        }
      });

    event.target.setAttribute('href', url.toString());
    window.history.pushState({}, '', url.toString());
    window.location.href = url.toString();
  };

  handleKeydown(event) {
    if (event.code === 'Escape') {
      this.filterListWrapperTarget.style.display = 'none';
    }
  }
}
