import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { backLinks: Array };

  navigateBack() {
    const previousUrl = document.referrer;

    if (previousUrl && this.backLinksValue.some((backLink) => previousUrl.includes(backLink))) {
      window.history.back();
    } else {
      [window.location.href] = this.backLinksValue;
    }
  }
}
