document.addEventListener('turbo:load', () => {
  const sidebar = document.querySelector('[data-mobile-sidebar]');
  const toggles = document.querySelectorAll('[data-sidebar-toggle]');

  toggles.forEach((toggle) => {
    toggle.addEventListener('click', () => {
      if (sidebar.classList.contains('hidden')) {
        sidebar.classList.remove('hidden');
        sidebar.classList.add('flex', 'md:hidden');
      } else {
        sidebar.classList.remove('flex', 'md:hidden');
        sidebar.classList.add('hidden');
      }
    });
  });
});
