import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  select(event) {
    const url = new URL(window.location.href);
    if (event.target.value !== '') {
      url.searchParams.set('participant_group', event.target.value);
    } else url.searchParams.delete('participant_group');
    window.location.href = url.href;
  }
}
