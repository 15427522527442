import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['description', 'descriptionPreview'];

  static classes = ['hidden'];

  static values = {
    collapsed: Boolean,
    showMoreText: String,
    showLessText: String,
  };

  toggleCollapseDescription = (event) => {
    if (this.collapsedValue) {
      this.descriptionTarget.classList.remove(this.hiddenClass);
      this.descriptionPreviewTarget.classList.add(this.hiddenClass);
      this.collapsedValue = false;
      // eslint-disable-next-line no-param-reassign
      event.target.innerHTML = this.showLessTextValue;
    } else {
      this.descriptionTarget.classList.add(this.hiddenClass);
      this.descriptionPreviewTarget.classList.remove(this.hiddenClass);
      this.collapsedValue = true;
      // eslint-disable-next-line no-param-reassign
      event.target.innerHTML = this.showMoreTextValue;
    }
  };
}
