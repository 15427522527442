import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    window.addEventListener('keydown', this.onEscape.bind(this));
    this.element.showModal();
  }

  disconnect() {
    window.removeEventListener('keydown', this.onEscape);
  }

  onEscape(event) {
    if (event.code !== 'Escape') return;

    event.preventDefault();
    this.remove();
  }

  remove() {
    this.element.close();
    this.element.remove();
  }
}
