import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    courseRatingUrl: String,
  };

  completeCourse() {
    window.open(this.courseRatingUrlValue, '_blank');
  }
}
